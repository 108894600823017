/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.country-circle {
  width: 25px;
  cursor: pointer;
}

.country-circle:hover {
  width: 35px;
}

.country-selector.container {
  height: 35px;
}

.submit-button {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.already-paid {
  font-size: 20px;
  font-weight: bold;
  color: #0ac704;
}

.mono-qr {
  max-width: 500px;
}

@media only screen and (max-width: 600px) {
  .mono-qr {
    max-width: 250px;
  }
}

.card-number-with-copy {
  display: ruby;
}

@keyframes blink {
  0% { color: black; }
  50% { color: gainsboro; }
  100% { color: black; }
}

.blink-text {
  animation: blink 0.5s linear;
}

.copy-button {
  max-width: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.comment-hint {
  color: black;
  font-weight: bold;
}